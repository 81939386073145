<template>
  <blog-detail-page />
</template>

<script>
import blogDetailPage from '../../components/User/Blog/blogDetailPage.vue'
export default {
  components: { blogDetailPage },
  mounted(){
    this.$vuetify.goTo(0,0)
  },
}
</script>

<style>

</style>