<template>
  <v-col
    class="px-0 py-0"
    style="
      color: #1b1b1b;
      text-align: center;
      font-family: 'MacPaw Fixel';
      font-style: normal;
      line-height: normal;
    "
  >
    <v-main>
      <Loader v-if="showLoader" />
      <v-container v-else>
        <v-row no-gutters align="center">
          <page-step
            :pages="[
              { id: 1, name: 'Блог', link: '/blog' },
              {
                id: 2,
                name: `${detailBlog.translations.title}`,
                link: `/blog/${detailBlog.translations.slug}`,
              },
            ]"
          />
          <v-row
            v-if="!$vuetify.breakpoint.smAndDown"
            no-gutters
            align="center"
            justify="end"
            style="padding: 20px 0px"
          >
            <div
              class="categoryBox"
              v-for="category in detailBlog.categories"
              :key="category.id"
            >
              {{ category.translations.name }}
            </div>
            <span class="blogCardOption" style="margin-left: 28px">{{
              new Date(detailBlog?.published_at).toLocaleDateString("uk-UA", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
            }}</span>
            <v-icon
              style="margin-left: 20px; margin-right: 8px"
              size="20px"
              color="#C0C0C0"
              >mdi-eye</v-icon
            >
            <span class="blogCardOption">{{ detailBlog.view_count }}</span>
          </v-row>
          <v-row
            v-else
            no-gutters
            align="center"
            justify="end"
            style="padding: 20px 0px"
          >
            <v-col cols="12" class="px-0 py-0">
              <v-row no-gutters align="center" justify="center">
                <div
                  class="categoryBox"
                  v-for="category in detailBlog.categories"
                  :key="category.id"
                >
                  {{ category.translations.name }}
                </div>
              </v-row>
            </v-col>
            <v-col cols="12" class="px-0 py-0" style="margin-top: 20px">
              <v-row no-gutters align="center" justify="center">
                <span class="blogCardOption">{{
                  new Date(detailBlog?.published_at).toLocaleDateString(
                    "uk-UA",
                    {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    }
                  )
                }}</span>
                <v-icon
                  style="margin-left: 20px; margin-right: 8px"
                  size="20px"
                  color="#C0C0C0"
                  >mdi-eye</v-icon
                >
                <span class="blogCardOption">{{ detailBlog.view_count }}</span>
              </v-row>
            </v-col>
          </v-row>
        </v-row>
        <v-row no-gutters justify="center" style="margin-bottom: 70px">
          <v-col cols="12" xl="8" lg="8" md="8" sm="12">
            <h1 class="blogName" style="margin-top: 20px">
              {{ detailBlog?.translations?.title }}
            </h1>
            <img
              :src="detailBlog?.images?.[0]?.path"
              alt="blog name"
              width="100%"
              :height="$vuetify.breakpoint.smAndDown ? '280px' : '460px'"
              style="margin-top: 40px; object-fit: cover"
              :style="$vuetify.breakpoint.smAndDown ? 'padding: 0px 20px;' : ''"
            />
            <div
              v-html="detailBlog.translations.content"
              style="margin-top: 40px; text-align: left"
              class="blogText"
            ></div>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" style="margin-top: 70px">
          <div
            style="
              width: 12px;
              height: 12px;
              background: #144fa9;
              border-radius: 50%;
            "
          />
          <v-col class="px-0 py-0" style="text-align: left">
            <h2
              style="
                color: var(--Black, #1b1b1b);
                font-family: 'MacPaw Fixel';
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-left: 8px;
              "
            >
              Рекомендовані статті
            </h2>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          align="center"
          style="margin-top: 40px; margin-bottom: 70px"
          v-if="!$vuetify.breakpoint.smAndDown"
        >
          <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide v-for="blog in randomBlogs" :key="blog.id">
              <blog-card :blog="blog" />
            </swiper-slide>
          </swiper>
        </v-row>
        <swiper v-else ref="mySwiper" :options="swiperMobileOptions">
          <swiper-slide
            v-for="blog in randomBlogs"
            :key="blog.id"
            :modules="swiperOptions.modules"
          >
            <blog-card :blog="blog" />
          </swiper-slide>
        </swiper>
      </v-container>
    </v-main>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import swiperOptionMixin from "../../../mixins/swiperOptionMixin";
import contentService from "../../../requests/Content/contentService";
import PageStep from "../../UI/pageStep.vue";
import BlogCard from "./blogCard.vue";
import Loader from "../../UI/Loader.vue";
export default {
  mixins: [swiperOptionMixin],
  components: {
    PageStep,
    BlogCard,
    Loader,
  },
  data: () => ({
    detailBlog: {},
    text: "",
    swiperMobileOptions: {
      slidesPerView: 1.2,
      spaceBetween: 16,
    },
    swiperOptions: {
      slidesPerView: 3,
    },
    randomBlogs: [],
    showLoader: true,
  }),
  mounted() {
    this.getDetailBlog();
  },
  methods: {
    async getDetailBlog() {
      await contentService
        .getBlogBySlug(this.$route.params.slug)
        .then((res) => {
          if (res.status == "Success") {
            this.detailBlog = res.data;
            this.getRandomBlog();
          }
        });
    },
    async getRandomBlog() {
      await contentService.getRandomBlog().then((res) => {
        if (res.status == "Success") {
          this.randomBlogs = res.data.filter((blog) => blog?.translations?.slug !== this.$route?.params?.slug);
          this.showLoader = false;
        }
      });
    },
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  watch: {
    locale: {
      handler() {
        this.showLoader = true;
        this.getDetailBlog();
      },
    },
    "$route.path": {
      handler() {
        this.$vuetify.goTo(0,0)
        this.showLoader = true;
        this.getDetailBlog();
      },
    },
  },
};
</script>

<style scoped>
.blogName {
  font-size: 32px;
  font-weight: 600;
}
.blogText {
  color: #4b5262;
  font-size: 16px;
  font-weight: 400;
}
.blogCardOption {
  color: #989898;
  font-size: 14px;
  font-weight: 400;
}
.categoryBox {
  border-radius: 10px;
  background: rgba(20, 79, 169, 0.1);
  padding: 4px 8px;
  width: max-content;
  height: max-content;
  font-size: 16px;
  font-weight: 400;
  margin-right: 12px;
  display: grid;
  place-items: center;
}
.blogCard {
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.blogCard:nth-child(1n) {
  padding-left: 0px !important;
}
.blogCard:nth-child(3n) {
  padding-right: 0px !important;
}
</style>